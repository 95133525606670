import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://92671e6d5dfd45b59fc375bc1973a7d9@o137724.ingest.us.sentry.io/304726',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['checkout-page-frontend'],
      behaviour: 'drop-error-if-contains-third-party-frames',
    }),
  ],
  tracesSampleRate: 0.0,
  ignoreErrors: [
    'TypeError: undefined is not a constructor',
    'ChunkLoadError',
    'TypeError: Load failed',
    'TypeError: Failed to fetch',
    'TypeError: cancelled',
    'Non-Error promise rejection captured with value:',
    "Your card's security code is incomplete.",
    'globalThis is not defined',
    'Loading CSS chunk',
    'Importing a module script failed.',
  ],
});
