import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { SWRConfig } from 'swr';

import Routes from './routes';

import './styles/index.css';

import ScrollToTop from '@shared/components/ScrollToTop';

export default function App() {
  return (
    <SWRConfig>
      <BrowserRouter>
        <CompatRouter>
          <ScrollToTop />
          <Routes />
        </CompatRouter>
      </BrowserRouter>
    </SWRConfig>
  );
}
