import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { getStoreDomain } from '@dashboard/lib/getStoreDomain';

// Lazy load layouts and route components
const Store = React.lazy(() => import('./store/store'));
const Dashboard = React.lazy(() => import('./dashboard/dashboard'));
const Unauthorized = React.lazy(() => import('./dashboard/unauthorized'));

const renderRoutes = () => {
  const storeDomain = getStoreDomain(window.location.hostname);

  if (storeDomain) {
    return (
      <Switch>
        <Route path="/" component={props => <Store {...props} subdomain={storeDomain} />} />
      </Switch>
    );
  }

  return (
    <React.Suspense fallback={null}>
      <Switch>
        <Route path="/store" component={Store} />
        <Route
          path={['/c', '/checkout', '/s', '/subscriptions', '/overlay', '/embed']}
          component={Store}
        />
        <Route
          path={['/login', '/signup', '/account', '/accept-invite']}
          component={Unauthorized}
        />
        <Route path="/dashboard" component={Dashboard} />
        <Redirect to="/dashboard" />
      </Switch>
    </React.Suspense>
  );
};

export default renderRoutes;
