import './sentry';
import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';

import App from './App';

ReactGA.initialize('G-608VH3FZ42', {
  testMode: process.env.NODE_ENV !== 'production',
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);
